import _ from "underscore";
import Backbone from "backbone";
import NotificationEllipsisView from "src/shared/notification/NotificationEllipsisView";
import ModalManager from "src/shared/ModalManager";
import confirmationTemplate from "src/shared/modals/confirmation-modal-template.html?raw";

const EVENTS = {
    CONFIRMED: "CONFIRMED",
    CANCELED: "CANCELED",
};

const ConfirmationModalView = Backbone.View.extend(
    {
        template: _.template(confirmationTemplate),

        events: {
            "click .js-cancel": "cancel",
            "click .js-confirm": "confirm",
        },

        initialize(parameters) {
            this.title = parameters.title || "Confirm";
            this.message = parameters.message;
            this.innerHTML = parameters.innerHTML;
            this.classname = parameters.classname;
            this.withCancelButton = parameters.withCancelButton;
            this.cancelButtonTitle = parameters.cancelButtonTitle;
            this.confirmButtonTitle = parameters.confirmButtonTitle;
        },

        render() {
            this.$el.html(
                this.template({
                    title: this.title,
                    message: this.message,
                    innerHTML: this.innerHTML,
                    classname: this.classname,
                    withCancelButton: this.withCancelButton,
                    cancelButtonTitle: this.cancelButtonTitle,
                    confirmButtonTitle: this.confirmButtonTitle,
                })
            );

            this.$confirmButton = this.$(".js-confirm");
            this.$errorMessage = this.$(".js-error");

            ModalManager.start(this.$el);
            NotificationEllipsisView.renderAll(this.$el);

            return this;
        },

        close() {
            ModalManager.closeModal();
        },

        confirm() {
            this.$confirmButton.prop("disabled", true);
            this.trigger(EVENTS.CONFIRMED);
        },

        cancel() {
            this.trigger(EVENTS.CANCELED);
            this.close();
        },

        unlockButton() {
            this.$confirmButton.prop("disabled", false);
        },
    },
    {
        EVENTS,
    }
);

ConfirmationModalView.Builder = function () {
    let _title = "Confirm";
    let _message;
    let _innerHTML;
    let _classname;
    let _withCancelButton = false;
    let _cancelButtonTitle = "Cancel";
    let _confirmButtonTitle = "Confirm";

    this.withTitle = function (title) {
        _title = title;
        return this;
    };

    this.withMessage = function (message) {
        _message = message;
        return this;
    };

    this.withInnerHTML = function (innerHTML) {
        _innerHTML = innerHTML;
        return this;
    };

    this.withAdditionalClassname = function (classname) {
        _classname = classname;
        return this;
    };

    this.withCancelButton = function (withCancelButton) {
        _withCancelButton = withCancelButton;
        return this;
    };

    this.withCancelButtonTitle = function (cancelButtonTitle) {
        _withCancelButton = true;
        _cancelButtonTitle = cancelButtonTitle;
        return this;
    };

    this.withConfirmButtonTitle = function (confirmButtonTitle) {
        _confirmButtonTitle = confirmButtonTitle;
        return this;
    };

    this.build = function () {
        return new ConfirmationModalView({
            title: _title,
            message: _message,
            innerHTML: _innerHTML,
            classname: _classname,
            withCancelButton: _withCancelButton,
            cancelButtonTitle: _cancelButtonTitle,
            confirmButtonTitle: _confirmButtonTitle,
        }).render();
    };
};

ConfirmationModalView.create = function (
    title,
    message,
    confirmButtonTitle,
    withCancelButton,
    cancelButtonTitle
) {
    return new ConfirmationModalView({
        title,
        message,
        withCancelButton,
        cancelButtonTitle,
        confirmButtonTitle,
    }).render();
};

export default ConfirmationModalView;
