// daterangepicker.js
// version : 0.0.5
// author : Chunlong Liu
// last updated at: 2014-05-27
// license : MIT
// www.jszen.com
import moment from "moment";
import jQuery from "jquery";
import "vendor/daterangepicker.css";
import "scss/vendor-overrides/jquery-datepicker.scss";

(function ($) {
    $.dateRangePickerLanguages = {
        cn: {
            selected: "已选择:",
            day: "天",
            days: "天",
            apply: "确定",
            "week-1": "一",
            "week-2": "二",
            "week-3": "三",
            "week-4": "四",
            "week-5": "五",
            "week-6": "六",
            "week-7": "日",
            "month-name": [
                "一月",
                "二月",
                "三月",
                "四月",
                "五月",
                "六月",
                "七月",
                "八月",
                "九月",
                "十月",
                "十一月",
                "十二月",
            ],
            shortcuts: "快捷选择",
            past: "过去",
            following: "将来",
            previous: "&nbsp;&nbsp;&nbsp;",
            "prev-week": "上周",
            "prev-month": "上个月",
            "prev-year": "去年",
            next: "&nbsp;&nbsp;&nbsp;",
            "next-week": "下周",
            "next-month": "下个月",
            "next-year": "明年",
            "less-than": "所选日期范围不能大于%d天",
            "more-than": "所选日期范围不能小于%d天",
            "default-more": "请选择大于%d天的日期范围",
            "default-less": "请选择小于%d天的日期范围",
            "default-range": "请选择%d天到%d天的日期范围",
            "default-single": "请选择一个日期",
            "default-default": "请选择一个日期范围",
        },
        cz: {
            selected: "Vybráno:",
            day: "Den",
            days: "Dny",
            apply: "Zavřít",
            "week-1": "Po",
            "week-2": "Út",
            "week-3": "St",
            "week-4": "Čt",
            "week-5": "Pá",
            "week-6": "So",
            "week-7": "Ne",
            "month-name": [
                "Leden",
                "Únor",
                "Březen",
                "Duben",
                "Květen",
                "Červen",
                "Červenec",
                "Srpen",
                "Září",
                "Říjen",
                "Listopad",
                "Prosinec",
            ],
            shortcuts: "Zkratky",
            past: "po",
            following: "následující",
            previous: "předchozí",
            "prev-week": "týden",
            "prev-month": "měsíc",
            "prev-year": "rok",
            next: "další",
            "next-week": "týden",
            "next-month": "měsíc",
            "next-year": "rok",
            "less-than": "Rozsah data by neměl být větší než %d dnů",
            "more-than": "Rozsah data by neměl být menší než %d dnů",
            "default-more": "Prosím zvolte rozsah data větší než %d dnů",
            "default-single": "Prosím zvolte datum",
            "default-less": "Prosím zvolte rozsah data menší než %d dnů",
            "default-range": "Prosím zvolte rozsah data mezi %d a %d dny",
            "default-default": "Prosím zvolte rozsah data",
        },
        en: {
            selected: "Selected:",
            day: "Day",
            days: "Days",
            apply: "Apply",
            "week-1": "MO",
            "week-2": "TU",
            "week-3": "WE",
            "week-4": "TH",
            "week-5": "FR",
            "week-6": "SA",
            "week-7": "SU",
            "month-name": [
                "JANUARY",
                "FEBRUARY",
                "MARCH",
                "APRIL",
                "MAY",
                "JUNE",
                "JULY",
                "AUGUST",
                "SEPTEMBER",
                "OCTOBER",
                "NOVEMBER",
                "DECEMBER",
            ],
            shortcuts: "Shortcuts",
            past: "Past",
            following: "Following",
            previous: "Previous",
            "prev-week": "Week",
            "prev-month": "Month",
            "prev-year": "Year",
            next: "Next",
            "next-week": "Week",
            "next-month": "Month",
            "next-year": "Year",
            "less-than": "Date range should not be more than %d days",
            "more-than": "Date range should not be less than %d days",
            "default-more": "Please select a date range longer than %d days",
            "default-single": "Please select a date",
            "default-less": "Please select a date range less than %d days",
            "default-range":
                "Please select a date range between %d and %d days",
            "default-default": "Please select a date range",
        },
        it: {
            selected: "Selezionati:",
            day: "Giorno",
            days: "Giorni",
            apply: "Chiudi",
            "week-1": "LU",
            "week-2": "MA",
            "week-3": "ME",
            "week-4": "GI",
            "week-5": "VE",
            "week-6": "SA",
            "week-7": "DO",
            "month-name": [
                "GENNAIO",
                "FEBBRAIO",
                "MARZO",
                "APRILE",
                "MAGGIO",
                "GIUGNO",
                "LUGLIO",
                "AGOSTO",
                "SETTEMBRE",
                "OTTOBRE",
                "NOVEMBRE",
                "DICEMBRE",
            ],
            shortcuts: "Scorciatoie",
            past: "Scorso",
            following: "Successivo",
            previous: "Precedente",
            "prev-week": "Settimana",
            "prev-month": "Mese",
            "prev-year": "Anno",
            next: "Prossimo",
            "next-week": "Settimana",
            "next-month": "Mese",
            "next-year": "Anno",
            "less-than": "L'intervallo non dev'essere maggiore di %d giorni",
            "more-than": "L'intervallo non dev'essere minore di %d giorni",
            "default-more": "Seleziona un intervallo maggiore di %d giorni",
            "default-single": "Seleziona una data",
            "default-less": "Seleziona un intervallo minore di %d giorni",
            "default-range":
                "Seleziona un intervallo compreso tra i %d e i %d giorni",
            "default-default": "Seleziona un intervallo di date",
        },
        es: {
            selected: "Seleccionado:",
            day: "Dia",
            days: "Dias",
            apply: "Cerrar",
            "week-1": "LU",
            "week-2": "MA",
            "week-3": "MI",
            "week-4": "JU",
            "week-5": "VI",
            "week-6": "SA",
            "week-7": "DO",
            "month-name": [
                "ENERO",
                "FEBRERO",
                "MARZO",
                "ABRIL",
                "MAYO",
                "JUNIO",
                "JULIO",
                "AGOSTO",
                "SEPTIEMBRE",
                "OCTUBRE",
                "NOVIEMBRE",
                "DICIEMBRE",
            ],
            shortcuts: "Accesos directos",
            past: "Pasado",
            following: "Siguiente",
            previous: "Anterior",
            "prev-week": "Semana",
            "prev-month": "Mes",
            "prev-year": "Año",
            next: "Siguiente",
            "next-week": "Semana",
            "next-month": "Mes",
            "next-year": "Año",
            "less-than": "El rango no deberia ser mayor de %d dias",
            "more-than": "El rango no deberia ser menor de %d dias",
            "default-more": "Por favor selecciona un rango mayor a %d dias",
            "default-single": "Por favor selecciona un dia",
            "default-less": "Por favor selecciona un rango menor a %d dias",
            "default-range": "Por favor selecciona un rango entre %d y %d dias",
            "default-default": "Por favor selecciona un rango de fechas.",
        },
        de: {
            selected: "Auswahl:",
            day: "Tag",
            days: "Tage",
            apply: "Schließen",
            "week-1": "MO",
            "week-2": "DI",
            "week-3": "MI",
            "week-4": "DO",
            "week-5": "FR",
            "week-6": "SA",
            "week-7": "SO",
            "month-name": [
                "JANUAR",
                "FEBRUAR",
                "MÄRZ",
                "APRIL",
                "MAI",
                "JUNI",
                "JULI",
                "AUGUST",
                "SEPTEMBER",
                "OKTOBER",
                "NOVEMBER",
                "DEZEMBER",
            ],
            shortcuts: "Schnellwahl",
            past: "Vorherige",
            following: "Folgende",
            previous: "Vorherige",
            "prev-week": "Woche",
            "prev-month": "Monat",
            "prev-year": "Jahr",
            next: "Nächste",
            "next-week": "Woche",
            "next-month": "Monat",
            "next-year": "Jahr",
            "less-than": "Datumsbereich darf nicht größer sein als %d Tage",
            "more-than": "Datumsbereich darf nicht kleiner sein als %d Tage",
            "default-more": "Bitte mindestens %d Tage auswählen",
            "default-single": "Bitte ein Datum auswählen",
            "default-less": "Bitte weniger als %d Tage auswählen",
            "default-range":
                "Bitte einen Datumsbereich zwischen %d und %d Tagen auswählen",
            "default-default": "Bitte ein Start- und Enddatum auswählen",
        },
        ru: {
            selected: "Выбрано:",
            day: "День",
            days: "Дней",
            apply: "Закрыть",
            "week-1": "ПН",
            "week-2": "ВТ",
            "week-3": "СР",
            "week-4": "ЧТ",
            "week-5": "ПТ",
            "week-6": "СБ",
            "week-7": "ВС",
            "month-name": [
                "ЯНВАРЬ",
                "ФЕВРАЛЬ",
                "МАРТ",
                "АПРЕЛЬ",
                "МАЙ",
                "ИЮНЬ",
                "ИЮЛЬ",
                "АВГУСТ",
                "СЕНТЯБРЬ",
                "ОКТЯБРЬ",
                "НОЯБРЬ",
                "ДЕКАБРЬ",
            ],
            shortcuts: "Быстрый выбор",
            past: "Прошедшие",
            following: "Следующие",
            previous: "&nbsp;&nbsp;&nbsp;",
            "prev-week": "Неделя",
            "prev-month": "Месяц",
            "prev-year": "Год",
            next: "&nbsp;&nbsp;&nbsp;",
            "next-week": "Неделя",
            "next-month": "Месяц",
            "next-year": "Год",
            "less-than": "Диапазон не может быть больше %d дней",
            "more-than": "Диапазон не может быть меньше %d дней",
            "default-more": "Пожалуйста выберите диапазон больше %d дней",
            "default-single": "Пожалуйста выберите дату",
            "default-less": "Пожалуйста выберите диапазон меньше %d дней",
            "default-range": "Пожалуйста выберите диапазон между %d и %d днями",
            "default-default": "Пожалуйста выберите диапазон",
        },
        fr: {
            selected: "Sélection:",
            day: "Jour",
            days: "Jours",
            apply: "Fermer",
            "week-1": "LU",
            "week-2": "MA",
            "week-3": "ME",
            "week-4": "JE",
            "week-5": "VE",
            "week-6": "SA",
            "week-7": "DI",
            "month-name": [
                "JANVIER",
                "FÉVRIER",
                "MARS",
                "AVRIL",
                "MAI",
                "JUIN",
                "JUILLET",
                "AOÛT",
                "SEPTEMBRE",
                "OCTOBRE",
                "NOVEMBRE",
                "DÉCEMBRE",
            ],
            shortcuts: "Raccourcis",
            past: "Passé",
            following: "Suivant",
            previous: "Précédent",
            "prev-week": "Semaine",
            "prev-month": "Mois",
            "prev-year": "Année",
            next: "Suivant",
            "next-week": "Semaine",
            "next-month": "Mois",
            "next-year": "Année",
            "less-than": "L'intervalle ne doit pas être supérieure à %d jours",
            "more-than": "L'intervalle ne doit pas être inférieure à %d jours",
            "default-more":
                "Merci de choisir une intervalle supérieure à %d jours",
            "default-single": "Merci de choisir une date",
            "default-less":
                "Merci de choisir une intervalle inférieure %d jours",
            "default-range":
                "Merci de choisir une intervalle comprise entre %d et %d jours",
            "default-default": "Merci de choisir une date",
        },
    };

    $.fn.dateRangePicker = function (opt) {
        if (!opt) opt = {};
        opt = $.extend(
            true,
            {
                autoClose: false,
                format: "YYYY-MM-DD",
                separator: " to ",
                language: "auto",
                startOfWeek: "sunday", // or monday
                getValue: function () {
                    return $(this).val();
                },
                setValue: function (s) {
                    if (!$(this).attr("readonly") && !$(this).is(":disabled")) {
                        $(this).val(s);
                    }
                },
                startDate: false,
                endDate: false,
                time: {
                    enabled: false,
                },
                minDays: 0,
                maxDays: 0,
                showShortcuts: true,
                shortcuts: {
                    //'prev-days': [1,3,5,7],
                    "next-days": [3, 5, 7],
                    //'prev' : ['week','month','year'],
                    next: ["week", "month", "year"],
                },
                clearDateValue: false,
                customShortcuts: [],
                inline: false,
                container: "body",
                alwaysOpen: false,
                singleDate: false,
                lookBehind: false,
                batchMode: false,
                duration: 200,
                stickyMonths: false,
            },
            opt
        );

        opt.start = false;
        opt.end = false;

        if (opt.startDate && typeof opt.startDate === "string")
            opt.startDate = moment(opt.startDate, opt.format).toDate();
        if (opt.endDate && typeof opt.endDate === "string")
            opt.endDate = moment(opt.endDate, opt.format).toDate();

        const langs = getLanguages();
        let box;
        let initiated = false;
        const self = this;
        const selfDom = $(self).get(0);

        $(this)
            .unbind(".datepicker")
            .bind("click.datepicker", function (evt) {
                const isOpen = box.is(":visible");
                $(document).trigger("click.datepicker");
                evt.stopPropagation();
                if (!isOpen) open(opt.duration);
            });

        init_datepicker.call(this);

        if (opt.alwaysOpen) {
            open(0);
        }

        // expose some api
        $(this).data("dateRangePicker", {
            setDateRange: function (d1, d2) {
                if (!d2) {
                    d2 = d1;
                }

                if (typeof d1 === "string" && typeof d2 === "string") {
                    d1 = moment(d1, opt.format).toDate();
                    d2 = moment(d2, opt.format).toDate();
                }

                if (opt.singleDate) {
                    setSingleDate(d1, true);
                } else {
                    setDateRange(d1, d2, true);
                }
            },
            clear: clearSelection,
            close: closeDatePicker,
            open: open,
            getDatePicker: getDatePicker,
            destroy: function () {
                $(self).unbind(".datepicker");
                $(self).data("dateRangePicker", "");
                box.remove();
                $(window).unbind("resize.datepicker", calcPosition);
                $(document).unbind("click.datepicker", closeDatePicker);
                $(document.body).unbind("click", updateDateRange);
            },
        });

        $(window).bind("resize.datepicker", calcPosition);
        $(document.body).bind("click", updateDateRange);

        function updateDateRange() {
            if (opt.singleDate) {
                $(self).trigger("datepicker-apply", {
                    date1: new Date(opt.start),
                });
            } else if (!opt.end) {
                const endDate = moment.utc(new Date());
                $(self).trigger("datepicker-apply", {
                    date1: new Date(opt.start),
                    date2: opt.timezone ? endDate.tz(opt.timezone) : endDate,
                });
            }
        }

        return this;

        function init_datepicker() {
            const self = this;

            if ($(this).data("date-picker-opened")) {
                closeDatePicker();
                return;
            }
            $(this).data("date-picker-opened", true);

            box = createDom.call(this).hide();
            $(opt.container).append(box);

            if (!opt.inline) {
                calcPosition();
            } else {
                box.addClass("inline-wrapper").css({ position: "static" });
            }

            if ((opt.alwaysOpen && opt.autoClose) || !opt.showApply) {
                box.find(".apply-btn-wrapper").hide();
            }

            let defaultTime = opt.defaultTime ? opt.defaultTime : new Date();
            if (opt.lookBehind) {
                if (
                    opt.startDate &&
                    compare_month(defaultTime, opt.startDate) < 0
                )
                    defaultTime = nextMonth(moment(opt.startDate).toDate());
                if (opt.endDate && compare_month(defaultTime, opt.endDate) > 0)
                    defaultTime = moment(opt.endDate).toDate();

                showMonth(prevMonth(defaultTime), "month1");
                showMonth(defaultTime, "month2");
            } else {
                if (
                    opt.startDate &&
                    compare_month(defaultTime, opt.startDate) < 0
                )
                    defaultTime = moment(opt.startDate).toDate();
                if (
                    opt.endDate &&
                    compare_month(nextMonth(defaultTime), opt.endDate) > 0
                )
                    defaultTime = prevMonth(moment(opt.endDate).toDate());

                showMonth(defaultTime, "month1");
                showMonth(nextMonth(defaultTime), "month2");
            }

            if (opt.time.enabled) {
                if ((opt.startDate && opt.endDate) || (opt.start && opt.end)) {
                    showTime(
                        moment(opt.start || opt.startDate).toDate(),
                        "time1"
                    );
                    showTime(moment(opt.end || opt.endDate).toDate(), "time2");
                } else {
                    showTime(defaultTime, "time1");
                    showTime(defaultTime, "time2");
                }
            }

            //showSelectedInfo();

            let defaultTopText = "";
            if (opt.singleDate) defaultTopText = lang("default-single");
            else if (opt.minDays && opt.maxDays)
                defaultTopText = lang("default-range");
            else if (opt.minDays) defaultTopText = lang("default-more");
            else if (opt.maxDays) defaultTopText = lang("default-less");
            else defaultTopText = lang("default-default");

            box.find(".default-top").html(
                defaultTopText
                    .replace(/\%d/, opt.minDays)
                    .replace(/\%d/, opt.maxDays)
            );

            setTimeout(function () {
                initiated = true;
            }, 0);

            box.click(function (evt) {
                evt.stopPropagation();
            });

            $(document).bind("click.datepicker", closeDatePicker);

            box.find(".js-next").click(function () {
                if (!opt.stickyMonths) gotoNextMonth(this);
                else gotoNextMonth_stickily(this);
            });

            function gotoNextMonth(self) {
                const isMonth2 = $(self).parents("table").hasClass("month2");
                let month = isMonth2 ? opt.month2 : opt.month1;
                month = nextMonth(month);
                if (
                    (!opt.singleDate &&
                        !isMonth2 &&
                        compare_month(month, opt.month2) >= 0) ||
                    isMonthOutOfBounds(month)
                )
                    return;
                showMonth(month, isMonth2 ? "month2" : "month1");
                showGap();
            }

            function gotoNextMonth_stickily(self) {
                const nextMonth1 = nextMonth(opt.month1);

                const nextMonth2 = nextMonth(opt.month2);

                if (isMonthOutOfBounds(nextMonth2)) return;
                if (
                    !opt.singleDate &&
                    compare_month(nextMonth1, nextMonth2) >= 0
                )
                    return;
                showMonth(nextMonth1, "month1");
                showMonth(nextMonth2, "month2");
            }

            box.find(".js-prev").click(function () {
                if (!opt.stickyMonths) gotoPrevMonth(this);
                else gotoPrevMonth_stickily(this);
            });

            function gotoPrevMonth(self) {
                const isMonth2 = $(self).parents("table").hasClass("month2");
                let month = isMonth2 ? opt.month2 : opt.month1;
                month = prevMonth(month);
                //if (isMonth2 && month.getFullYear()+''+month.getMonth() <= opt.month1.getFullYear()+''+opt.month1.getMonth()) return;
                if (
                    (isMonth2 && compare_month(month, opt.month1) <= 0) ||
                    isMonthOutOfBounds(month)
                )
                    return;
                showMonth(month, isMonth2 ? "month2" : "month1");
                showGap();
            }

            function gotoPrevMonth_stickily(self) {
                const prevMonth1 = prevMonth(opt.month1);

                const prevMonth2 = prevMonth(opt.month2);

                if (isMonthOutOfBounds(prevMonth1)) return;
                if (
                    !opt.singleDate &&
                    compare_month(prevMonth2, prevMonth1) <= 0
                )
                    return;
                showMonth(prevMonth2, "month2");
                showMonth(prevMonth1, "month1");
            }

            box.bind("click", function (evt) {
                if ($(evt.target).hasClass("day")) {
                    dayClicked($(evt.target));
                }
            });

            box.attr("unselectable", "on")
                .css("user-select", "none")
                .bind("selectstart", function (e) {
                    e.preventDefault();
                    return false;
                });

            box.find(".apply-btn").click(function () {
                closeDatePicker();
                const dateRange =
                    getDateString(new Date(opt.start)) +
                    opt.separator +
                    getDateString(new Date(opt.end));

                $(self).trigger("datepicker-apply", {
                    value: dateRange,
                    date1: new Date(opt.start),
                    date2: new Date(opt.end),
                });
            });

            box.find("[shortcut]").click(function () {
                const shortcut = $(this).attr("shortcut");
                let end = new Date(),
                    start = false;
                if (shortcut.indexOf("day") != -1) {
                    const day = parseInt(shortcut.split(",", 2)[1], 10);
                    start = new Date(new Date().getTime() + 86400000 * day);
                    end = new Date(
                        end.getTime() + 86400000 * (day > 0 ? 1 : -1)
                    );
                } else if (shortcut.indexOf("week") != -1) {
                    var dir = shortcut.indexOf("prev,") != -1 ? -1 : 1;

                    if (dir == 1)
                        var stopDay = opt.startOfWeek == "monday" ? 1 : 0;
                    else var stopDay = opt.startOfWeek == "monday" ? 0 : 6;

                    end = new Date(end.getTime() - 86400000);
                    while (end.getDay() != stopDay)
                        end = new Date(end.getTime() + dir * 86400000);
                    start = new Date(end.getTime() + dir * 86400000 * 6);
                } else if (shortcut.indexOf("month") != -1) {
                    var dir = shortcut.indexOf("prev,") != -1 ? -1 : 1;
                    if (dir == 1) start = nextMonth(end);
                    else start = prevMonth(end);
                    start.setDate(1);
                    end = nextMonth(start);
                    end.setDate(1);
                    end = new Date(end.getTime() - 86400000);
                } else if (shortcut.indexOf("year") != -1) {
                    var dir = shortcut.indexOf("prev,") != -1 ? -1 : 1;
                    start = new Date();
                    start.setFullYear(end.getFullYear() + dir);
                    start.setMonth(0);
                    start.setDate(1);
                    end.setFullYear(end.getFullYear() + dir);
                    end.setMonth(11);
                    end.setDate(31);
                } else if (shortcut == "custom") {
                    const name = $(this).html();
                    if (opt.customShortcuts && opt.customShortcuts.length > 0) {
                        for (let i = 0; i < opt.customShortcuts.length; i++) {
                            const sh = opt.customShortcuts[i];
                            if (sh.name == name) {
                                let data = [];
                                // try
                                // {
                                data = sh["dates"].call();
                                //}catch(e){}
                                if (data && data.length == 2) {
                                    start = data[0];
                                    end = data[1];
                                }

                                // if only one date is specified then just move calendars there
                                // move calendars to show this date's month and next months
                                if (data && data.length == 1) {
                                    movetodate = data[0];
                                    showMonth(movetodate, "month1");
                                    showMonth(nextMonth(movetodate), "month2");
                                    showGap();
                                }

                                break;
                            }
                        }
                    }
                }

                if (start && end) {
                    setDateRange(start, end);
                    checkSelectionValid();
                    renderTimezones();
                }
            });

            box.find(".time1 input[type=range]").bind(
                "change mousemove",
                function (e) {
                    let { target } = e,
                        hour =
                            target.name == "hour"
                                ? $(target)
                                      .val()
                                      .replace(/^(\d{1})$/, "0$1")
                                : undefined,
                        min =
                            target.name == "minute"
                                ? $(target)
                                      .val()
                                      .replace(/^(\d{1})$/, "0$1")
                                : undefined;
                    setTime("time1", hour, min, true);
                    if (e.type === "change") {
                        box.find(".js-datepicker-start-date").html(
                            formatDateTime(opt.start || opt.startTime)
                        );
                        $(self).trigger("datepicker-change-time", {
                            timeField: "start_date",
                            hour: hour,
                            min: min,
                        });
                    }
                }
            );

            box.find(".time2 input[type=range]").bind(
                "change mousemove",
                function (e) {
                    let { target } = e,
                        hour =
                            target.name == "hour"
                                ? $(target)
                                      .val()
                                      .replace(/^(\d{1})$/, "0$1")
                                : undefined,
                        min =
                            target.name == "minute"
                                ? $(target)
                                      .val()
                                      .replace(/^(\d{1})$/, "0$1")
                                : undefined;
                    setTime("time2", hour, min, true);

                    if (e.type === "change") {
                        if (opt.end) {
                            box.find(".js-datepicker-end-date").html(
                                formatDateTime(opt.end)
                            );
                            $(self).trigger("datepicker-change-time", {
                                timeField: "end_date",
                                hour: hour,
                                min: min,
                            });
                        } else {
                            box.find(".js-datepicker-end-date").html("--");
                        }
                    }
                }
            );

            box.find(".clear-calendar-value").click(function () {
                $(self).trigger("datepicker-clear", {
                    value: null,
                    date1: null,
                });
            });
        }

        function calcPosition() {
            if (!opt.inline) {
                const offset = $(self).offset();
                if ($(opt.container).css("position") == "relative") {
                    const containerOffset = $(opt.container).offset();
                    box.css({
                        top:
                            offset.top -
                            containerOffset.top +
                            $(self).outerHeight() +
                            4,
                        left: offset.left - containerOffset.left,
                    });
                } else {
                    if (offset.left < 460) {
                        //left to right
                        box.css({
                            top:
                                offset.top +
                                $(self).outerHeight() +
                                parseInt($("body").css("border-top") || 0, 10),
                            left: offset.left,
                        });
                    } else {
                        box.css({
                            top:
                                offset.top +
                                $(self).outerHeight() +
                                parseInt($("body").css("border-top") || 0, 10),
                            left:
                                offset.left +
                                $(self).width() -
                                box.width() -
                                16,
                        });
                    }
                }
            }
        }

        // Return the date picker wrapper element
        function getDatePicker() {
            return box;
        }

        function open(animationTime) {
            calcPosition();
            const __default_string = opt.getValue.call(selfDom);
            const defaults = __default_string
                ? __default_string.split(opt.separator)
                : "";

            if (
                defaults &&
                ((defaults.length == 1 && opt.singleDate) ||
                    defaults.length >= 2)
            ) {
                let ___format = opt.format;
                if (___format.match(/Do/)) {
                    ___format = ___format.replace(/Do/, "D");
                    defaults[0] = defaults[0].replace(/(\d+)(th|nd|st)/, "$1");
                    if (defaults.length >= 2) {
                        defaults[1] = defaults[1].replace(
                            /(\d+)(th|nd|st)/,
                            "$1"
                        );
                    }
                }
                // set initiated  to avoid triggerring datepicker-change event
                initiated = false;
                if (defaults.length >= 2) {
                    setDateRange(
                        moment(defaults[0], ___format).toDate(),
                        moment(defaults[1], ___format).toDate()
                    );
                } else if (defaults.length == 1 && opt.singleDate) {
                    setSingleDate(moment(defaults[0], ___format).toDate());
                }

                initiated = true;
            }
            box.slideDown(animationTime);
        }

        function renderTime(name, date) {
            box.find("." + name + " input[type=range].hour-range").val(
                moment(date).hours()
            );
            box.find("." + name + " input[type=range].minute-range").val(
                moment(date).minutes()
            );
            setTime(name, moment(date).format("HH"), moment(date).format("mm"));
        }

        function changeTime(name, date) {
            opt[name] = parseInt(
                moment(parseInt(date))
                    .startOf("day")
                    .add("h", moment(opt[name + "Time"]).format("HH"))
                    .add("m", moment(opt[name + "Time"]).format("mm"))
                    .valueOf()
            );
        }

        function swapTime() {
            renderTime("time1", opt.start);
            renderTime("time2", opt.end);
        }

        function setTime(name, hour, minute, shouldKeepOpen) {
            if (hour) {
                const ampm = parseInt(hour) >= 12 ? "PM" : "AM";
                let hours = hour % 12;
                hours = hours ? hours : 12;
                box.find("." + name + " .hour-val").text(hours);
                box.find("." + name + " .ampm-val").text(ampm);
            }
            minute && box.find("." + name + " .minute-val").text(minute);
            switch (name) {
                case "time1":
                    if (opt.start) {
                        setRange("start", moment(opt.start));
                    }
                    setRange(
                        "startTime",
                        moment(opt.startTime || moment().valueOf())
                    );
                    break;
                case "time2":
                    if (opt.end) {
                        setRange("end", moment(opt.end));
                    }
                    setRange(
                        "endTime",
                        moment(opt.endTime || moment().valueOf())
                    );
                    break;
            }

            renderTimezones();

            function setRange(name, timePoint) {
                const h = timePoint.format("HH"),
                    m = timePoint.format("mm");
                opt[name] = timePoint
                    .startOf("day")
                    .add("h", hour || h)
                    .add("m", minute || m)
                    .valueOf();
            }

            if (!shouldKeepOpen) {
                checkSelectionValid();
                showSelectedInfo();
                showSelectedDays();
            }
        }

        function clearSelection() {
            opt.start = false;
            opt.end = false;
            box.find(".day.checked").removeClass("checked");
            opt.setValue.call(selfDom, "");
            checkSelectionValid();
            showSelectedInfo();
            showSelectedDays();
        }

        function handleStart(time, hours, minutes) {
            let r = time;
            if (opt.batchMode === "week-range") {
                if (opt.startOfWeek === "monday") {
                    r = moment(parseInt(time)).startOf("isoweek").valueOf();
                } else {
                    r = moment(parseInt(time)).startOf("week").valueOf();
                }
            } else if (opt.batchMode === "month-range") {
                r = moment(parseInt(time)).startOf("month").valueOf();
            }

            return handleTimestamp(r, hours, minutes);
        }

        function handleEnd(time, hours, minutes) {
            let r = time;
            if (opt.batchMode === "week-range") {
                if (opt.startOfWeek === "monday") {
                    r = moment(parseInt(time)).endOf("isoweek").valueOf();
                } else {
                    r = moment(parseInt(time)).endOf("week").valueOf();
                }
            } else if (opt.batchMode === "month") {
                r = moment(parseInt(time)).endOf("month").valueOf();
            }

            return handleTimestamp(r, hours, minutes);
        }

        function handleTimestamp(time, hours, minutes) {
            if (hours) {
                return moment(parseInt(time))
                    .startOf("day")
                    .add("hours", hours)
                    .add("minutes", minutes)
                    .valueOf();
            }

            return time;
        }

        function dayClicked(day) {
            if (day.hasClass("invalid")) return;
            const time = day.attr("time");
            day.addClass("checked");
            if (opt.singleDate) {
                opt.start = time;
                opt.end = false;
                if (opt.time.enabled) {
                    changeTime("start", opt.start);
                }
            } else if (opt.batchMode === "week") {
                if (opt.startOfWeek === "monday") {
                    opt.start = moment(parseInt(time))
                        .startOf("isoweek")
                        .valueOf();
                    opt.end = moment(parseInt(time)).endOf("isoweek").valueOf();
                } else {
                    opt.end = moment(parseInt(time)).endOf("week").valueOf();
                    opt.start = moment(parseInt(time))
                        .startOf("week")
                        .valueOf();
                }
            } else if (opt.batchMode === "month") {
                opt.start = moment(parseInt(time)).startOf("month").valueOf();
                opt.end = moment(parseInt(time)).endOf("month").valueOf();
            } else if ((opt.start && opt.end) || (!opt.start && !opt.end)) {
                opt.start = handleStart(time);
                opt.end = false;
                if (opt.time.enabled) {
                    changeTime("start", opt.start);
                }
            } else if (opt.start) {
                opt.end = handleEnd(time);
                if (opt.time.enabled) {
                    changeTime("end", opt.end);
                }
            }

            if (
                !opt.singleDate &&
                opt.start &&
                opt.end &&
                opt.start > opt.end
            ) {
                const tmp = opt.end;
                const startHours = moment(opt.start).format("H");
                const startMinutes = moment(opt.start).format("mm");
                const endHours = moment(tmp).format("H");
                const endMinutes = moment(tmp).format("mm");
                opt.end = handleEnd(opt.start, endHours, endMinutes);
                opt.start = handleStart(tmp, startHours, startMinutes);
                if (opt.time.enabled) {
                    swapTime();
                }
            }

            opt.start = parseInt(opt.start);
            opt.end = parseInt(opt.end);

            if (opt.shouldInitializeTime) {
                opt.start &&
                    renderTime(
                        "time1",
                        moment(opt.start).startOf("day").valueOf()
                    );
                opt.end &&
                    renderTime("time2", moment(opt.end).endOf("day").valueOf());
            }

            renderTimezones();
            checkSelectionValid();
            showSelectedInfo();
            showSelectedDays();
            autoclose();
        }

        function autoclose() {
            if (opt.singleDate === true) {
                if (initiated && opt.start) {
                    if (opt.autoClose) closeDatePicker();
                }
            } else {
                if (initiated && opt.start && opt.end) {
                    if (opt.autoClose) closeDatePicker();
                }
            }
        }

        function renderTimezones() {
            if (opt.time.enabled && opt.timezone) {
                opt.start &&
                    box
                        .find(".time1 .timezone-val")
                        .text(
                            moment(new Date(opt.start))
                                .tz(opt.timezone)
                                .format("z")
                        );
                opt.end &&
                    box
                        .find(".time2 .timezone-val")
                        .text(
                            moment(new Date(opt.end))
                                .tz(opt.timezone)
                                .format("z")
                        );
            }
        }

        function checkSelectionValid() {
            const days = Math.ceil((opt.end - opt.start) / 86400000) + 1;
            if (opt.singleDate) {
                // Validate if only start is there
                if (opt.start && !opt.end)
                    box.find(".drp_top-bar")
                        .removeClass("error")
                        .addClass("normal");
                else
                    box.find(".drp_top-bar")
                        .removeClass("error")
                        .removeClass("normal");
            } else if (opt.maxDays && days > opt.maxDays) {
                opt.start = false;
                opt.end = false;
                box.find(".day").removeClass("checked");
                box.find(".drp_top-bar")
                    .removeClass("normal")
                    .addClass("error")
                    .find(".error-top")
                    .html(lang("less-than").replace("%d", opt.maxDays));
            } else if (opt.minDays && days < opt.minDays) {
                opt.start = false;
                opt.end = false;
                box.find(".day").removeClass("checked");
                box.find(".drp_top-bar")
                    .removeClass("normal")
                    .addClass("error")
                    .find(".error-top")
                    .html(lang("more-than").replace("%d", opt.minDays));
            } else {
                if (opt.start || opt.end)
                    box.find(".drp_top-bar")
                        .removeClass("error")
                        .addClass("normal");
                else
                    box.find(".drp_top-bar")
                        .removeClass("error")
                        .removeClass("normal");
            }

            if (
                (opt.singleDate && opt.start && !opt.end) ||
                (!opt.singleDate && opt.start && opt.end)
            ) {
                box.find(".apply-btn").removeClass("disabled");
            } else {
                box.find(".apply-btn").addClass("disabled");
            }

            if (opt.batchMode) {
                if (
                    (opt.start &&
                        opt.startDate &&
                        compare_day(opt.start, opt.startDate) < 0) ||
                    (opt.end &&
                        opt.endDate &&
                        compare_day(opt.end, opt.endDate) > 0)
                ) {
                    opt.start = false;
                    opt.end = false;
                    box.find(".day").removeClass("checked");
                }
            }
        }

        function showSelectedInfo(isOpened) {
            box.find(".start-day").html("...");
            box.find(".end-day").html("...");
            box.find(".selected-days").hide();
            box.find(".js-datepicker-start-date").html("--");
            box.find(".js-datepicker-end-date").html("--");
            if (opt.start) {
                const startTime = new Date(parseInt(opt.start));
                box.find(".start-day").html(getDateString(startTime));
                box.find(".js-datepicker-start-date").html(
                    formatDateTime(startTime)
                );
            }
            if (opt.end) {
                const endTime = new Date(parseInt(opt.end));
                box.find(".end-day").html(getDateString(endTime));
                box.find(".js-datepicker-end-date").html(
                    formatDateTime(endTime)
                );
            }
            renderTimezones();

            if (opt.start && opt.singleDate) {
                box.find(".apply-btn").removeClass("disabled");
                var dateRange = getDateString(new Date(opt.start));
                opt.setValue.call(
                    selfDom,
                    dateRange,
                    getDateString(new Date(opt.start)),
                    getDateString(new Date(opt.end))
                );

                if (initiated && !isOpened) {
                    $(self).trigger("datepicker-change", {
                        value: dateRange,
                        date1: new Date(opt.start),
                    });
                }
            } else if (opt.start && opt.end) {
                box.find(".selected-days")
                    .show()
                    .find(".selected-days-num")
                    .html(Math.round((opt.end - opt.start) / 86400000) + 1);
                box.find(".apply-btn").removeClass("disabled");
                var dateRange =
                    getDateString(new Date(opt.start)) +
                    opt.separator +
                    getDateString(new Date(opt.end));
                opt.setValue.call(
                    selfDom,
                    dateRange,
                    getDateString(new Date(opt.start)),
                    getDateString(new Date(opt.end))
                );
                if (initiated && !isOpened) {
                    $(self).trigger("datepicker-change", {
                        value: dateRange,
                        date1: new Date(opt.start),
                        date2: new Date(opt.end),
                    });
                }
            } else {
                box.find(".apply-btn").addClass("disabled");
            }
        }

        function setDateRange(date1, date2, isOpened) {
            if (date1.getTime() > date2.getTime()) {
                let tmp = date2;
                date2 = date1;
                date1 = tmp;
                tmp = null;
            }
            let valid = true;
            if (opt.startDate && compare_day(date1, opt.startDate) < 0)
                valid = false;

            if (opt.singleDate && valid) {
                showMonth(date1, "month1");
                showGap();
                autoclose();

                if (opt.time.enabled) {
                    renderTime("time1", date1);
                }
                return;
            }

            if (opt.endDate && compare_day(date2, opt.endDate) > 0)
                valid = false;
            if (!valid) {
                showMonth(opt.startDate, "month1");
                showMonth(nextMonth(opt.startDate), "month2");
                showGap();
                return;
            }

            opt.start = date1.getTime();
            opt.end = date2.getTime();
            if (
                opt.stickyMonths ||
                (compare_day(date1, date2) > 0 &&
                    compare_month(date1, date2) == 0)
            ) {
                if (opt.lookBehind) {
                    date1 = prevMonth(date2);
                } else {
                    date2 = nextMonth(date1);
                }
            }

            if (opt.stickyMonths && compare_month(date2, opt.endDate) > 0) {
                date1 = prevMonth(date1);
                date2 = prevMonth(date2);
            }

            if (!opt.stickyMonths && !opt.constDates) {
                if (compare_month(date1, date2) == 0) {
                    if (opt.lookBehind) {
                        date1 = prevMonth(date2);
                    } else {
                        date2 = nextMonth(date1);
                    }
                }
            }

            if (opt.time.enabled) {
                renderTime("time1", date1);
                renderTime("time2", date2);
            }
            showMonth(date1, "month1");
            showMonth(date2, "month2");
            showGap();
            showSelectedInfo(isOpened);
            autoclose();
        }

        function setSingleDate(date1, isOpened) {
            let valid = true;
            if (opt.startDate && compare_day(date1, opt.startDate) < 0)
                valid = false;
            if (opt.endDate && compare_day(date1, opt.endDate) > 0)
                valid = false;
            if (!valid) {
                showMonth(opt.startDate, "month1");

                //showGap();
                return;
            }

            opt.start = date1.getTime();

            if (opt.time.enabled) {
                renderTime("time1", date1);
            }
            showMonth(date1, "month1");
            //showMonth(date2,'month2');
            showGap();
            showSelectedInfo(isOpened);
            autoclose();
        }

        function showSelectedDays() {
            if (!opt.start && !opt.end) return;
            box.find(".day").each(function () {
                let time = parseInt($(this).attr("time")),
                    { start } = opt,
                    { end } = opt;
                if (opt.time.enabled) {
                    time = moment(time).startOf("day").valueOf();
                    start = moment(start || moment().valueOf())
                        .startOf("day")
                        .valueOf();
                    end = moment(end || moment().valueOf())
                        .startOf("day")
                        .valueOf();
                }
                if (
                    (opt.start && opt.end && end >= time && start <= time) ||
                    (opt.start &&
                        !opt.end &&
                        moment(start).format("YYYY-MM-DD") ==
                            moment(time).format("YYYY-MM-DD"))
                ) {
                    $(this).addClass("checked");
                } else {
                    $(this).removeClass("checked");
                }
            });
        }

        function showMonth(date, month) {
            date = moment(date).toDate();
            const monthName = nameMonth(date.getMonth());
            box.find("." + month + " .month-name").html(
                monthName + " " + date.getFullYear()
            );
            box.find("." + month + " tbody").html(createMonthHTML(date));
            opt[month] = date;
        }

        function showTime(date, name) {
            box.find("." + name).append(getTimeHTML());
            renderTime(name, date);
        }

        function nameMonth(m) {
            return lang("month-name")[m];
        }

        function getDateString(d) {
            return moment(d).format(opt.format);
        }

        function showGap() {
            showSelectedDays();
            const m1 = parseInt(moment(opt.month1).format("YYYYMM"));
            const m2 = parseInt(moment(opt.month2).format("YYYYMM"));
            const p = Math.abs(m1 - m2);
            const shouldShow = p > 1 && p != 89;
            if (shouldShow) box.find(".gap").show();
            else box.find(".gap").hide();
        }

        function closeDatePicker() {
            if (opt.alwaysOpen) return;
            $(box).slideUp(opt.duration, function () {
                $(self).data("date-picker-opened", false);
            });
            //$(document).unbind('.datepicker');
            $(self).trigger("datepicker-close");
        }

        function compare_month(m1, m2) {
            const p =
                parseInt(moment(m1).format("YYYYMM")) -
                parseInt(moment(m2).format("YYYYMM"));
            if (p > 0) return 1;
            if (p == 0) return 0;
            return -1;
        }

        function compare_day(m1, m2) {
            const p =
                parseInt(moment(m1).format("YYYYMMDD")) -
                parseInt(moment(m2).format("YYYYMMDD"));
            if (p > 0) return 1;
            if (p == 0) return 0;
            return -1;
        }

        function nextMonth(month) {
            month = moment(month).toDate();
            const toMonth = month.getMonth();
            while (month.getMonth() == toMonth)
                month = new Date(month.getTime() + 86400000);
            return month;
        }

        function prevMonth(month) {
            month = moment(month).toDate();
            const toMonth = month.getMonth();
            while (month.getMonth() == toMonth)
                month = new Date(month.getTime() - 86400000);
            return month;
        }

        function getTimeHTML() {
            const timeHtml =
                "<div>" +
                '<span class="time__label">Time: <span class="hour-val">00</span>:<span class="minute-val">00</span> <span class="ampm-val"></span> <span class="timezone-val"></span></span>' +
                "</div>" +
                '<div class="hour">' +
                '<label>Hour: <input type="range" class="hour-range" name="hour" min="0" max="23"></label>' +
                "</div>" +
                '<div class="minute">' +
                '<label>Minute: <input type="range" class="minute-range" name="minute" min="0" max="59"></label>' +
                "</div>";
            return timeHtml;
        }

        function addLeadingZero(number) {
            const string = number.toString();
            if (string.padStart === "function") {
                return string.padStart(2, 0);
            }

            if (number < 10) {
                return "0" + number;
            }

            return number;
        }

        function formatDateTime(date) {
            if (!date) {
                return;
            }

            if (typeof date === "number" || typeof date === "string") {
                date = new Date(date);
            }

            let hours = date.getHours();
            const ampm = hours >= 12 ? "PM" : "AM";
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'

            let formattedDateTime =
                date.getMonth() +
                1 +
                "/" +
                date.getDate() +
                "/" +
                date.getFullYear() +
                " " +
                hours +
                ":" +
                addLeadingZero(date.getMinutes()) +
                ":" +
                addLeadingZero(date.getSeconds()) +
                " " +
                ampm;

            if (opt.timezone) {
                formattedDateTime +=
                    " " + moment(new Date(date)).tz(opt.timezone).format("z");
            }

            return formattedDateTime;
        }

        function createDom() {
            let html = '<div class="date-picker-wrapper';
            if (opt.singleDate) html += " single-date";
            if (!opt.showShortcuts) html += " no-shortcuts ";
            html +=
                '">' +
                '<div class="drp_top-bar">\
					<div class="normal-top">\
						<span style="color:#333">' +
                lang("selected") +
                ' </span> <b class="start-day">...</b>';
            if (!opt.singleDate) {
                html +=
                    ' <span class="separator-day">' +
                    opt.separator +
                    '</span> <b class="end-day">...</b> <i class="selected-days">(<span class="selected-days-num">3</span> ' +
                    lang("days") +
                    ")</i>";
            }
            html +=
                '</div>\
					<div class="error-top">error</div>\
					<div class="default-top">default</div>\
				</div>' +
                '<div class="month-wrapper">' +
                '<table class="month1" cellspacing="0" border="0" cellpadding="0"><thead><tr class="caption"><th class="js-prev" style="width:27px;"><span class="prev"></span></th><th colspan="5" class="month-name">January, 2011</th><th class="js-next" style="width:27px;">' +
                (opt.singleDate || !opt.stickyMonths
                    ? '<span class="next"></span>'
                    : "") +
                '</th></tr><tr class="week-name">' +
                getWeekHead() +
                "</thead><tbody></tbody></table>";
            if (!opt.singleDate) {
                html +=
                    '<div class="gap">' +
                    getGapHTML() +
                    "</div>" +
                    '<table class="month2" cellspacing="0" border="0" cellpadding="0"><thead><tr class="caption"><th class="js-prev" style="width:27px;">' +
                    (!opt.stickyMonths ? '<span class="prev"></span>' : "") +
                    '</th><th colspan="5" class="month-name">January, 2011</th><th class="js-next" style="width:27px;"><span class="next"></span></th></tr><tr class="week-name">' +
                    getWeekHead() +
                    "</thead><tbody></tbody></table>";
            }
            //+'</div>'
            html +=
                '<div style="clear:both;height:0;font-size:0;"></div>' +
                '<div class="time">' +
                '<div class="time1"></div>';
            if (!opt.singleDate) {
                html += '<div class="time2"></div>';
            }
            html +=
                "</div>" +
                '<div style="clear:both;height:0;font-size:0;"></div>';

            if (opt.time && opt.time.enabled) {
                html +=
                    '<div class="time-display text-center">' +
                    '<span class="js-datepicker-start-date time-display__box"></span>';

                if (!opt.singleDate) {
                    html +=
                        (opt.separator || " - ") +
                        '<span class="js-datepicker-end-date time-display__box"></span>';
                }

                html +=
                    "</div>" +
                    '<div class="apply-btn-wrapper button-wrapper text-right">' +
                    '<button type="button" class="apply-btn button button--primary button--medium disabled ' +
                    getHideClass() +
                    '">' +
                    lang("apply") +
                    "</button>" +
                    "</div>" +
                    "</div>";
            }

            if (opt.showShortcuts) {
                html += '<div class="footer"><b>' + lang("shortcuts") + "</b>";

                const data = opt.shortcuts;
                if (data) {
                    if (data["prev-days"] && data["prev-days"].length > 0) {
                        html += '&nbsp;<span class="prev-days">' + lang("past");
                        for (var i = 0; i < data["prev-days"].length; i++) {
                            var name = data["prev-days"][i];
                            name +=
                                data["prev-days"][i] > 1
                                    ? lang("days")
                                    : lang("day");
                            html +=
                                ' <a href="javascript:;" shortcut="day,-' +
                                data["prev-days"][i] +
                                '">' +
                                name +
                                "</a>";
                        }
                        html += "</span>";
                    }

                    if (data["next-days"] && data["next-days"].length > 0) {
                        html +=
                            '&nbsp;<span class="next-days">' +
                            lang("following");
                        for (var i = 0; i < data["next-days"].length; i++) {
                            var name = data["next-days"][i];
                            name +=
                                data["next-days"][i] > 1
                                    ? lang("days")
                                    : lang("day");
                            html +=
                                ' <a href="javascript:;" shortcut="day,' +
                                data["next-days"][i] +
                                '">' +
                                name +
                                "</a>";
                        }
                        html += "</span>";
                    }

                    if (data["prev"] && data["prev"].length > 0) {
                        html +=
                            '&nbsp;<span class="prev-buttons">' +
                            lang("previous");
                        for (var i = 0; i < data["prev"].length; i++) {
                            var name = lang("prev-" + data["prev"][i]);
                            html +=
                                ' <a href="javascript:;" shortcut="prev,' +
                                data["prev"][i] +
                                '">' +
                                name +
                                "</a>";
                        }
                        html += "</span>";
                    }

                    if (data["next"] && data["next"].length > 0) {
                        html +=
                            '&nbsp;<span class="next-buttons">' + lang("next");
                        for (var i = 0; i < data["next"].length; i++) {
                            var name = lang("next-" + data["next"][i]);
                            html +=
                                ' <a href="javascript:;" shortcut="next,' +
                                data["next"][i] +
                                '">' +
                                name +
                                "</a>";
                        }
                        html += "</span>";
                    }
                }

                if (opt.customShortcuts) {
                    for (var i = 0; i < opt.customShortcuts.length; i++) {
                        const sh = opt.customShortcuts[i];
                        html +=
                            '&nbsp;<span class="custom-shortcut"><a href="javascript:;" shortcut="custom">' +
                            sh.name +
                            "</a></span>";
                    }
                }

                html += "</div>";
            }
            if (opt.clearDateValue) {
                html +=
                    '<div style="clear:both;height:0;font-size:0;"></div>' +
                    '<div class="calendar-bottom-actions__wrapper">' +
                    '<div class="action-button clear-calendar-value">Clear</div>' +
                    "</div>";
            }

            html += "</div>";

            return $(html);
        }

        function getHideClass() {
            if (opt.autoClose === true) {
                return "hide";
            }
            return "";
        }

        function getWeekHead() {
            if (opt.startOfWeek == "monday") {
                return (
                    "<th>" +
                    lang("week-1") +
                    "</th>\
					<th>" +
                    lang("week-2") +
                    "</th>\
					<th>" +
                    lang("week-3") +
                    "</th>\
					<th>" +
                    lang("week-4") +
                    "</th>\
					<th>" +
                    lang("week-5") +
                    "</th>\
					<th>" +
                    lang("week-6") +
                    "</th>\
					<th>" +
                    lang("week-7") +
                    "</th>"
                );
            } else {
                return (
                    "<th>" +
                    lang("week-7") +
                    "</th>\
					<th>" +
                    lang("week-1") +
                    "</th>\
					<th>" +
                    lang("week-2") +
                    "</th>\
					<th>" +
                    lang("week-3") +
                    "</th>\
					<th>" +
                    lang("week-4") +
                    "</th>\
					<th>" +
                    lang("week-5") +
                    "</th>\
					<th>" +
                    lang("week-6") +
                    "</th>"
                );
            }
        }
        function isMonthOutOfBounds(month) {
            var month = moment(month);
            if (opt.startDate && month.endOf("month").isBefore(opt.startDate)) {
                $(self).trigger("datepicker-out-of-bound-start", opt.startDate);
                return true;
            }
            if (opt.endDate && month.startOf("month").isAfter(opt.endDate)) {
                $(self).trigger("datepicker-out-of-bound-end", opt.endDate);
                return true;
            }
            return false;
        }

        function getGapHTML() {
            const html = [
                '<div class="gap-top-mask"></div><div class="gap-bottom-mask"></div><div class="gap-lines">',
            ];
            for (let i = 0; i < 20; i++) {
                html.push(
                    '<div class="gap-line">\
					<div class="gap-1"></div>\
					<div class="gap-2"></div>\
					<div class="gap-3"></div>\
				</div>'
                );
            }
            html.push("</div>");
            return html.join("");
        }

        function createMonthHTML(d) {
            const days = [];
            d.setDate(1);
            const now = new Date();

            let dayOfWeek = d.getDay();
            if (dayOfWeek == 0 && opt.startOfWeek == "monday") {
                // add one week
                dayOfWeek = 7;
            }

            if (dayOfWeek > 0) {
                for (var i = dayOfWeek; i > 0; i--) {
                    var day = new Date(d.getTime() - 86400000 * i);
                    var valid = true;
                    if (opt.startDate && compare_day(day, opt.startDate) < 0)
                        valid = false;
                    if (opt.endDate && compare_day(day, opt.endDate) > 0)
                        valid = false;
                    days.push({
                        type: "lastMonth",
                        day: day.getDate(),
                        time: day.getTime(),
                        valid: valid,
                    });
                }
            }
            const toMonth = d.getMonth();
            for (var i = 0; i < 40; i++) {
                var today = moment(d).add(i, "days").toDate();
                var valid = true;
                if (opt.startDate && compare_day(today, opt.startDate) < 0)
                    valid = false;
                if (opt.endDate && compare_day(today, opt.endDate) > 0)
                    valid = false;
                days.push({
                    type: today.getMonth() == toMonth ? "toMonth" : "nextMonth",
                    day: today.getDate(),
                    time: today.getTime(),
                    valid: valid,
                });
            }
            const html = [];
            for (let week = 0; week < 6; week++) {
                if (days[week * 7].type == "nextMonth") break;
                html.push("<tr>");
                for (var day = 0; day < 7; day++) {
                    const _day = opt.startOfWeek == "monday" ? day + 1 : day;
                    var today = days[week * 7 + _day];
                    const highlightToday =
                        moment(today.time).format("L") ==
                        moment(now).format("L");
                    today.extraClass = "";
                    today.tooltip = "";
                    if (
                        opt.beforeShowDay &&
                        typeof opt.beforeShowDay === "function"
                    ) {
                        const _r = opt.beforeShowDay(
                            moment(today.time).toDate()
                        );
                        today.valid = _r[0];
                        today.extraClass = _r[1] || "";
                        today.tooltip = _r[2] || "";
                        if (today.tooltip != "")
                            today.extraClass += " has-tooltip ";
                    }
                    html.push(
                        '<td><div time="' +
                            today.time +
                            '" title="' +
                            today.tooltip +
                            '" class="day ' +
                            today.type +
                            " " +
                            today.extraClass +
                            " " +
                            (today.valid ? "valid" : "invalid") +
                            " " +
                            (highlightToday ? "real-today" : "") +
                            '">' +
                            today.day +
                            "</div></td>"
                    );
                }
                html.push("</tr>");
            }
            return html.join("");
        }

        function getLanguages() {
            if (opt.language == "auto") {
                var language = navigator.language
                    ? navigator.language
                    : navigator.browserLanguage;
                if (!language) return $.dateRangePickerLanguages["en"];
                var language = language.toLowerCase();
                for (const key in $.dateRangePickerLanguages) {
                    if (language.indexOf(key) != -1) {
                        return $.dateRangePickerLanguages[key];
                    }
                }
                return $.dateRangePickerLanguages["en"];
            } else if (
                opt.language &&
                opt.language in $.dateRangePickerLanguages
            ) {
                return $.dateRangePickerLanguages[opt.language];
            } else {
                return $.dateRangePickerLanguages["en"];
            }
        }

        function lang(t) {
            return t in langs ? langs[t] : t;
        }
    };
})(jQuery);
