import $ from "jquery";
import _ from "underscore";
import Keys from "src/shared/Keys";

function ModalManager() {
    const state = {
        previousItem: null,
        currentItem: null,
        stack: [],
    };
    const $body = $(document.body);

    this.start = function (_$modal, className, $parent) {
        const self = this;
        this.$root = $parent?.length ? $parent : $body;

        state.stack.push(_$modal);

        if (state.currentItem) {
            state.previousItem = state.currentItem;
        }

        state.currentItem = _$modal;
        state.currentItem
            .find(".js-close")
            .on("click", this.closeModal.bind(this));
        this.$root.addClass("nonscrolling");
        if (className) {
            state.currentItem.addClass(className);
        }
        this.$root.append(state.currentItem);
        $(document).keyup((event) => {
            if (event && event.keyCode === Keys.ESCAPE) {
                self.closeModal(event);
            }
        });
    };

    this.closeModal = function (event, closePrevious) {
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        if (closePrevious) {
            state.previousItem.remove();
            state.stack = _.without(state.stack, state.previousItem);
            return;
        }
        if (!_.isEmpty(state.stack)) {
            const modalToRemove = state.stack.pop();
            modalToRemove.remove();
        }
        if (_.isEmpty(state.stack)) {
            this.$root.removeClass("nonscrolling");
            state.currentItem = null;
        }
    };
}

export default new ModalManager();
